import React from 'react'
import { Box, Grid } from 'theme-ui'
import './soulwork.css'

import Petroglyphs from '../../images/approach/soulwork/petroglyphs.svg'
import PetroLeft from '../../images/approach/soulwork/petro-left.svg'
import PetroRight from '../../images/approach/soulwork/petro-right.svg'
import Dreamcatcher from '../../images/approach/soulwork/dreamcatcher.svg'

export function petroImg() {
  return (
    <Grid row={1} columns={2} sx={{ position: 'absolute', width: '100%' }}>
      <Box sx={{
          position: 'absolute',
          zIndex: '-1',
          width: '20%',
          left: ['-16%','-14%', '-8%', null],
          marginTop: '20em'
        }}>
      <PetroLeft
        className="petroglyphs"
        
        />
      </Box>
      <Box sx={{
          position: 'absolute',
          zIndex: '-1',
          width: '20%',
          marginTop: ['25em','15em'],
          right: ['2%', 0, '-4%']
        }}>
      <PetroRight
        className="petroglyphs"
      />
      </Box>
    </Grid>
  )
}

export function soulMobile(paraNumber) {
  if (paraNumber === 4) {
    return (
      <Box sx={{ width: ['80%','50%', '40%'], margin: '1em auto' }}>
        <Dreamcatcher style={{ width: '100%' }} />
      </Box>
    )
  }
}

function Soulwork({ children }) {
  return (
    <Box>
      <Petroglyphs className="petroglyphs" />
      <Dreamcatcher style={{ width: '80%', marginTop: '6em' }} />
    </Box>
  )
}

export default Soulwork
