import React from 'react'
import { Box } from 'theme-ui'
import './soulwork.css'

import abuse from '../../images/approach/relationship/abused.png'
import dominoes from '../../images/approach/relationship/dominoes.png'

export function abuseImg() {
  return (
    <Box sx={{ width: ['100%', '70%', '50%','100%'], margin: '0em auto 2em'}}>
      <img
        src={abuse}
        alt="crying woman curled up with her arms around her legs"
        sx={{
          width: '100%',
          position: 'relative',
          display: 'block',
          marginBottom: 0,
        }}
      />
    </Box>
  )
}

function dominoesImg() {
  return (
    <Box sx={{ margin: ['0 auto', '0 auto', '0 auto', '18em 0 0'],width: ['90%', '80%', '50%', '100%']}}>
    <img
      src={dominoes}
      alt="a line of dominoes"
      style={{
        width: '100%',
        position: 'relative',
        display: 'block',
        marginBottom: 0,
      }}
      />
      </Box>
  )
}

export function relationshipsMobile(paraNumber) {
  if (paraNumber === 3) {
    return dominoesImg()
  }
}

function Relationship({ children }) {
  return (
    <Box>
      {abuseImg()}
      {dominoesImg()}
    </Box>
  )
}

export default Relationship
