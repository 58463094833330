import React from 'react'
import { useColorMode, Box } from 'theme-ui'
import './soulwork.css'

import trees from '../../images/approach/nature/trees.png'
import shelter from '../../images/approach/nature/shelter.png'
import lake from '../../images/approach/nature/peaceful-lake.png'
import canoeing from '../../images/approach/nature/canoeing.png'

export function treesImg() {
  return (
    <Box
      sx={{
        width: ['70%', '50%', '50%', '100%'],
        margin: '2em auto',
        marginTop: [0, 0, '3em', '1em'],
      }}
    >
      <img
        src={trees}
        alt="line art of trees on a hill"
        style={{
          width: '100%',
          position: 'relative',
          display: 'block',
          marginBottom: 0,
        }}
      />
    </Box>
  )
}

function shelterImg() {
  return (
    <Box
      sx={{
        width: ['100%', '80%', '50%', '100%'],
        margin: ['2em auto'],
        marginTop: ['2em', '2em', '3em', '6em'],
      }}
    >
      <img
        src={shelter}
        alt="line art of shelter made of hay and branches"
        style={{
          width: '100%',
          position: 'relative',
          display: 'block',
          marginBottom: 0,
        }}
      />
    </Box>
  )
}

function lakeImg() {
  return (
    <Box
      sx={{
        width: ['100%', '80%', '50%', '100%'],
        margin: ['1em auto'],
        marginTop: ['1em', '1em', '1em', '5em'],
      }}
    >
      <img
        src={lake}
        alt="line art of lake scene"
        style={{
          width: '100%',
          position: 'relative',
          display: 'block',
          marginBottom: 0,
          padding: '4em',
        }}
      />
    </Box>
  )
}

function canoeImg() {
  return (
    <Box
      sx={{
        width: ['100%', '80%', '50%', '100%'],
        margin: ['2em auto'],
        marginTop: ['2em', '2em', '2em', '0em'],
      }}
    >
      <img
        src={canoeing}
        alt="line art of woman canoeing"
        style={{
          width: '100%',
          position: 'relative',
          display: 'block',
          marginBottom: 0,
          padding: '2em',
        }}
      />
    </Box>
  )
}

export function natureMobile(paraNumber) {
  if (paraNumber === 2) {
    return shelterImg()
  }

  if (paraNumber === 3) {
    return lakeImg()
  }

  if (paraNumber === 4) {
    return canoeImg()
  }
}

function Nature({ children }) {
  const [colorMode] = useColorMode()
  const isDark = colorMode === 'dark'

  return (
    <Box>
      {treesImg()}
      {shelterImg()}
      {lakeImg()}
      {canoeImg()}
    </Box>
  )
}

export default Nature
