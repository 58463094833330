import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import { Container, Grid, Text, Heading, Box } from 'theme-ui'
import useWindowSize from '../hooks/useWindowSize'

import Brain, { brainMobile, brainImage } from '../components/graphics/brain'
import Relationship, {
  relationshipsMobile,
  abuseImg,
} from '../components/graphics/relationship'
import Soulwork, { soulMobile, petroImg } from '../components/graphics/soulwork'
import Nature, { natureMobile, treesImg } from '../components/graphics/nature'

function returnApproachGraphic(title) {
  var page = title ? title.toLowerCase() : null
  switch (page) {
    case 'depth-work':
      return <Soulwork />
    case 'relationship therapy':
      return <Relationship />
    case 'brain-based therapy':
      return <Brain />
    case 'nature therapy':
      return <Nature />
    default:
      return null
  }
}

function returnParaGraphic(title, i) {
  var page = title ? title.toLowerCase() : null
  switch (page) {
    case 'depth-work':
      return soulMobile(i)
    case 'relationship therapy':
      return relationshipsMobile(i)
    case 'brain-based therapy':
      return brainMobile(i)
    case 'nature therapy':
      return natureMobile(i)
    default:
      return null
  }
}

function topImage(title) {
  var page = title ? title.toLowerCase() : null
  switch (page) {
    case 'depth-work':
      return petroImg()
    case 'relationship therapy':
      return abuseImg()
    case 'brain-based therapy':
      return brainImage()
    case 'nature therapy':
      return treesImg()
    default:
      return null
  }
}

const Approach = ({ pageContext }) => {
  console.log(pageContext)
  const { approach } = pageContext
  const title = approach.title
  const graphic = returnApproachGraphic(title)
  const { width } = useWindowSize()
  const isMobile = width < 1024 ? true : false
  const text = approach.text
  const listItems = text.map((number, i) => (
    <Text
      as="p"
      variant="paragraph"
      key={'para' + i}
      dangerouslySetInnerHTML={{ __html: number.paragraph }}
    />
  ))
  const listItemsMobile = text.map((number, i) => (
    <>
    <Text
      as="p"
      variant="paragraph"
      key={'para' + i}
      dangerouslySetInnerHTML={{ __html: number.paragraph }}
      />
      {returnParaGraphic(title, i)}
      </>
  ))
  return (
    <Layout variant="sidebar">
      <Container variant="layout">
        {isMobile ? topImage(title) : null}
        <Heading as="h1" variant="upperHeading">
          {title}
        </Heading>
        <Heading as="h1" variant="h1Centered" className='soft-faux-font'>
          {approach.subheading}
        </Heading>
        <Grid
          gap={[4]}
          row={[1]}
          columns={[1, 1, 1, '3fr 2fr']}
          sx={{
            padding: ['1em 2em', '1em 2em', 0],
            '@media only screen and (max-width: 500px)': { padding: '0.5em 0.5em' }
          }}
        >
          {!isMobile ? (
            <>
              <Box>{listItems}</Box>
              <Box>{graphic}</Box>
            </>
          ) : (
            <Box>{listItemsMobile}</Box>
          )}
        </Grid>
      </Container>
    </Layout>
  )
}

export default Approach
